import React, {useState} from 'react'
import ReactDOM from 'react-dom/client'
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Popover from '../comps/Popover';
import FormGroup from '@mui/material/FormGroup';
import {loadMyEmploymentRelationship,hasActiveWork, checkInToSpecificEmploymentRelationship} from "../Utils/UtilsApi"
import PropLabelMegjelenito from '../comps/PropLabelMegjelenito';
import {PropsLabelArrayMegjelenites,createSwitch} from "../Utils/UtilsVsmEmployee"
import Alert from '@mui/material/Alert';
import Map from "../map/Map"
import { Button } from 'react-bootstrap';
import {checkInAutomatic as UtilsApi_checkInAutomatic} from "../Utils/UtilsApi"
import {getLocationOfBrowser as UtilsGeolocation_getLocationOfBrowser} from "../Utils/UtilsGeolocation"
import { Coords } from '../types/Types';
import {components } from "../../../src-kozos/src/types/api-types"
import { useNavigate } from "react-router-dom";
import { showError, showInfo, showUtilsApiError } from '../Utils/UtilsDialog';

export default class MyEmploymentRelationshipAdatlap extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.id = props.id
        this.additionalButton=props.button
        this.onSaveOrUpdateListener = props.onSaveOrUpdate
        this.entitas = this.props.entitas
        this.state = {
            entitas: {
                id: null
            },
            hasActiveWork: true,
            saved: this.entitas?.id ? true : false
        };        
    }


    /*
    ██       ██████   █████  ██████  ███████ ███    ██ ████████ ██ ████████  █████  ███████
    ██      ██    ██ ██   ██ ██   ██ ██      ████   ██    ██    ██    ██    ██   ██ ██
    ██      ██    ██ ███████ ██   ██ █████   ██ ██  ██    ██    ██    ██    ███████ ███████
    ██      ██    ██ ██   ██ ██   ██ ██      ██  ██ ██    ██    ██    ██    ██   ██      ██
    ███████  ██████  ██   ██ ██████  ███████ ██   ████    ██    ██    ██    ██   ██ ███████
    */

    loadEntitas = async (id) => {
        try {
            const work = await loadMyEmploymentRelationship(id)
            this.setState({
                entitas: work,
                saved: work.id?true:false
            })
        } catch(error) {
            alert(error?.data?.message)
            console.log("LOAD ERROR !!!");
            console.log(error);
            console.log(error.data);
        }        
    }

    loadHasActiveWork = async () => {
        let b=null;
        try {
            b = await hasActiveWork()
        } catch(error) {
            if(error?.data?.message)
                alert(error?.data?.message)
            else 
                alert("Error. Read the console.")
            console.log("Check-in error:");
            console.log(error);
            console.log(error.data);
            return;
        }   
        this.setState({
            hasActiveWork: b
        })             
    }    

    /*
     ██████  ██████  ███    ███ ██████   ██████  ███    ██ ███████ ███    ██ ████████ ██████  ██ ██████  ███    ███  ██████  ██    ██ ███    ██ ████████
    ██      ██    ██ ████  ████ ██   ██ ██    ██ ████   ██ ██      ████   ██    ██    ██   ██ ██ ██   ██ ████  ████ ██    ██ ██    ██ ████   ██    ██
    ██      ██    ██ ██ ████ ██ ██████  ██    ██ ██ ██  ██ █████   ██ ██  ██    ██    ██   ██ ██ ██   ██ ██ ████ ██ ██    ██ ██    ██ ██ ██  ██    ██
    ██      ██    ██ ██  ██  ██ ██      ██    ██ ██  ██ ██ ██      ██  ██ ██    ██    ██   ██ ██ ██   ██ ██  ██  ██ ██    ██ ██    ██ ██  ██ ██    ██
     ██████  ██████  ██      ██ ██       ██████  ██   ████ ███████ ██   ████    ██    ██████  ██ ██████  ██      ██  ██████   ██████  ██   ████    ██
    */

    componentDidMount = () => {
        if (!this.entitas && this.id) this.loadEntitas(this.id)
        this.loadHasActiveWork();
    }


    /*
    ██████  ███████ ███    ██ ██████  ███████ ██████
    ██   ██ ██      ████   ██ ██   ██ ██      ██   ██
    ██████  █████   ██ ██  ██ ██   ██ █████   ██████
    ██   ██ ██      ██  ██ ██ ██   ██ ██      ██   ██
    ██   ██ ███████ ██   ████ ██████  ███████ ██   ██
    */

    render() {


        /*
        ██████  ███████ ████████ ██    ██ ██████  ███    ██
        ██   ██ ██         ██    ██    ██ ██   ██ ████   ██
        ██████  █████      ██    ██    ██ ██████  ██ ██  ██
        ██   ██ ██         ██    ██    ██ ██   ██ ██  ██ ██
        ██   ██ ███████    ██     ██████  ██   ██ ██   ████
        */

        const entitas=this.state.entitas
        const employee=entitas?.employee

        return (
            <>
                <h2 className="mb-4 pb-2">
                
                { this.state.entitas.id &&
                <Popover popovertext={"ID: "+this.state.entitas.id}>
                    My employment relationship 
                </Popover>
                }
                
                
                </h2>
                <div style={{ border: "solid 1px lightgray", padding: "20px", borderRadius: "20px", backgroundColor:"#fafafa", }}>
                    
                {
                    entitas.active?
                        <Alert  className="my-2"  severity="success">ACTIVE</Alert>:
                        <Alert  className="my-2" severity="error">INACTIVE</Alert>
                                            
                }                    


                    {/*
                    ███████ ███    ███ ██████  ██       ██████  ██    ██ ███████ ███████
                    ██      ████  ████ ██   ██ ██      ██    ██  ██  ██  ██      ██
                    █████   ██ ████ ██ ██████  ██      ██    ██   ████   █████   █████
                    ██      ██  ██  ██ ██      ██      ██    ██    ██    ██      ██
                    ███████ ██      ██ ██      ███████  ██████     ██    ███████ ███████
                    */}

                    <Row>
                        <Col className="col-12 p-2 pb-3 pb-sm-2">
                            <Popover popovertext={"ID: " + this.state.entitas?.employee?.id}>
                                Employee: &nbsp;
                                {

                                    PropsLabelArrayMegjelenites({
                                        obj: employee,
                                        enclosing:null,
                                        delimeter: " ",
                                        props_labels_renders: [
                                            "firstName",
                                            "lastName"],
                                        showLabels: false
                                    }) 

                                    
                                }
                                {
                                    employee?.socialSecurityNumber &&
                                    ` (SSN: ${employee.socialSecurityNumber})`
                                }
                            </Popover>

                           
                        </Col>
                    </Row>



                    {/*
                    ███████ ███    ███ ██████  ██       ██████  ██    ██ ███    ███ ███████ ███    ██ ████████
                    ██      ████  ████ ██   ██ ██      ██    ██  ██  ██  ████  ████ ██      ████   ██    ██
                    █████   ██ ████ ██ ██████  ██      ██    ██   ████   ██ ████ ██ █████   ██ ██  ██    ██
                    ██      ██  ██  ██ ██      ██      ██    ██    ██    ██  ██  ██ ██      ██  ██ ██    ██
                    ███████ ██      ██ ██      ███████  ██████     ██    ██      ██ ███████ ██   ████    ██


                    ██████  ███████ ██       █████  ████████ ██  ██████  ███    ██ ███████ ██   ██ ██ ██████
                    ██   ██ ██      ██      ██   ██    ██    ██ ██    ██ ████   ██ ██      ██   ██ ██ ██   ██
                    ██████  █████   ██      ███████    ██    ██ ██    ██ ██ ██  ██ ███████ ███████ ██ ██████
                    ██   ██ ██      ██      ██   ██    ██    ██ ██    ██ ██  ██ ██      ██ ██   ██ ██ ██
                    ██   ██ ███████ ███████ ██   ██    ██    ██  ██████  ██   ████ ███████ ██   ██ ██ ██


                    ██████  ██       ██████  ██   ██ ██   ██
                    ██   ██ ██      ██    ██ ██  ██  ██  ██
                    ██████  ██      ██    ██ █████   █████
                    ██   ██ ██      ██    ██ ██  ██  ██  ██
                    ██████  ███████  ██████  ██   ██ ██   ██
                    */}
                    <div className="mt-2">
                        <EmploymentRelationshipBlokk
                            employmentRelationship={entitas}
                            hasActiveWork={this.state.hasActiveWork}
                            onChangeHasActiveWork={work=>{this.setState({hasActiveWork:work?true:work.active})}}
                        />
                    </div>



                    {/*
                     █████  ██████  ██████  ██ ████████ ██  ██████  ███    ██  █████  ██
                    ██   ██ ██   ██ ██   ██ ██    ██    ██ ██    ██ ████   ██ ██   ██ ██
                    ███████ ██   ██ ██   ██ ██    ██    ██ ██    ██ ██ ██  ██ ███████ ██
                    ██   ██ ██   ██ ██   ██ ██    ██    ██ ██    ██ ██  ██ ██ ██   ██ ██
                    ██   ██ ██████  ██████  ██    ██    ██  ██████  ██   ████ ██   ██ ███████


                    ██████  ██    ██ ████████ ████████  ██████  ███    ██
                    ██   ██ ██    ██    ██       ██    ██    ██ ████   ██
                    ██████  ██    ██    ██       ██    ██    ██ ██ ██  ██
                    ██   ██ ██    ██    ██       ██    ██    ██ ██  ██ ██
                    ██████   ██████     ██       ██     ██████  ██   ████
                    */}                

                    <Row className="justify-content-between">
                        {
                            this.additionalButton &&
                            <Col>
                                    {React.cloneElement(this.additionalButton,{ className: "m-2 ms-0 mt-3" })}
                            </Col>
                        }
                    </Row>
                </div>
            </>
        );
    }
}



const EmploymentRelationshipBlokk = ({
    employmentRelationship,
    hasActiveWork,
    onChangeHasActiveWork 
}) => {
    const [myCoords,setMyCoords]=useState<Coords | null>(null);
    const mapCenterAndZoom=
        employmentRelationship?.client?.mapCenterAndZoom?
            JSON.parse(employmentRelationship.client.mapCenterAndZoom):
            null
    const mapPolygon=
        employmentRelationship?.client?.mapPolygon?
            JSON.parse(employmentRelationship.client.mapPolygon):
            null
    const navigate = useNavigate();  

    const handleCheckIn = async () => {
        let coords:Coords
        try {
            coords = await UtilsGeolocation_getLocationOfBrowser();
            console.log("Coords:");
            console.log(coords);
        } catch (error) {
            await showError(error);
            return
        }
        setMyCoords(coords)
        let work:components["schemas"]["WorkDTOForEmployee"] | null=null;
        try {
            work = await checkInToSpecificEmploymentRelationship(coords,employmentRelationship.id)
            showInfo("Successful check-in")
            console.log(work);
        } catch(error) {
            await showUtilsApiError(error);
            return;
        }   
        navigate(`/router/works/${work.id}/${work.version}`)     
    }

    

    return (<>

        {employmentRelationship &&
            <Row>
                <Col className="col-12 p-2 pb-3 pb-sm-2">
                    Client:&nbsp;
                    <Popover popovertext={"ID: " + employmentRelationship?.client?.id}>
                        {employmentRelationship?.client?.companyName}&nbsp;
                        {PropsLabelArrayMegjelenites({
                            obj: employmentRelationship?.client,
                            props_labels_renders: [
                                "address",
                                "city",
                                "state",
                                "zipCode"],
                            showLabels: false
                        })}
                    </Popover>
                </Col>
            </Row>
        }

        {/*
        ███    ███  █████  ██████
        ████  ████ ██   ██ ██   ██
        ██ ████ ██ ███████ ██████
        ██  ██  ██ ██   ██ ██
        ██      ██ ██   ██ ██
        */}

        <div className="my-4">
            {
                employmentRelationship.active &&
                employmentRelationship?.client?.mapCenterAndZoom &&
                employmentRelationship?.client?.mapPolygon &&
                <Map 
                    key={1} 
                    centerAndZoomValue={mapCenterAndZoom}
                    polygonValue={mapPolygon}
                    markerCoords={myCoords?{lat:myCoords.latitude,lng:myCoords.longitude}:null}

                    />
            }
        </div>



        {employmentRelationship &&
            <Row>
                <Col className="col-12 p-2 pb-3 pb-sm-2">
                    Position:&nbsp;
                    <Popover popovertext={"ID: " + employmentRelationship?.client?.id}>
                        {employmentRelationship?.clientPosition?.name}
                    </Popover>
                </Col>
            </Row>
        }



        <Row>
            <Col className="col-12 p-2 pb-3 pb-sm-2">
                {
                    [
                        [
                            {p:"beginning",l:"Beginning"},
                            {p:"ending",l:"Ending"},
                        ],

                        [
                            { p: "hourlyRate", l: "Hourly rate", r: v => "US$ " + v },
                            { p: "overTime", l: "Over time", r: v => "US$ " + v },
                        ],

                        [
                            {p:"bonusBeginning",l:"Bonus beginning"},
                            {p:"bonusDays",l:"Interval",r: v => v+" days"},
                            {p:"bonusAmount",l:"Bonus amount",r: v => "US$ " + v },
                            {p:"bonusEarlyTermination",l:"Bonus early termination"},
                        ]
                    ].map((t,i)=>{
                        return(<div className="mb-2" key={i}>
                        {t.map( (e,y) => 
                            <PropLabelMegjelenito 
                                o={employmentRelationship}
                                key={i+"-"+y}
                                p={e.p}
                                l={e.l}
                                r={e.r}
                                w="150px"
                                />
                        )}
                        </div>)
                    })
                }
            </Col>
        </Row>


        { 
            employmentRelationship.active && 
            hasActiveWork==false &&
            <Button variant="primary" onClick={handleCheckIn} >CHECK-IN</Button>
        }
     

    </>)
}

