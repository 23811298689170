import React from 'react'
import ReactDataGrid from '@inovua/reactdatagrid-community'
import '@inovua/reactdatagrid-community/index.css'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { detect } from "detect-browser";
import WorkAdatlap from "./WorkAdatlap"
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import _ from 'lodash';
import {getNestedValue} from "../UtilsVSM"
import * as UtilsReactDataGridIO from "../../../src-kozos/src/utils/UtilsReactDataGridIO"
import * as UtilsVSM from "../../../src-kozos/src/utils/UtilsVSM"
import {loadMyWorks} from "../Utils/UtilsApi"
import StringFilter from '@inovua/reactdatagrid-community/StringFilter'
import { TypeFilterTypes, TypeFilterValue } from '@inovua/reactdatagrid-community/types';


export default class WorkTabla extends React.Component {

    tablaKontextusNev:string
    generateAmountRef:React.RefObject<unknown>
    kezdoAllapot: {
        verzio:number,
        columns:UtilsReactDataGridIO.OszlopKonfiguracio[],
        reservedViewportWidth:number,
        enableFiltering:boolean,
        limit: number,
        backupverzio:number,
        columnOrder?: string[] | undefined
    }
    renderelok:UtilsReactDataGridIO.RenderFunctions 
    filterTypes: TypeFilterTypes | undefined
    filterValue: TypeFilterValue | undefined
    columnFilterSupported: boolean

    constructor(props) {
        super(props);
        this.tablaKontextusNev = props.ktxname
        this.generateAmountRef=React.createRef();
        this.renderelok = {
            ...UtilsReactDataGridIO.createRendersWithHandlers(
                {[UtilsReactDataGridIO.AkciorendereloNev.view]:this.onAkcioAdatlapClicked}),
            ...UtilsReactDataGridIO.createRenders(
                [UtilsReactDataGridIO.RendereloNev.multiPropBooleanRender])                
        }

        const tabledef:(UtilsReactDataGridIO.ShortColumnDefinition | string)[]=[
            {p:"id",t:UtilsReactDataGridIO.OszlopTipus.NUMBER,sf:true},
            {p:"employmentRelationship.id",l:"ERS id"},
            {p:"forBackup_Position",l:"Position",sf:true},
            {p:"employee.firstName",l:"first name",sf:true},
            {p:"employee.lastName",l:"Last name"},
            {
                p:"active", 
                t:UtilsReactDataGridIO.OszlopTipus.BOOLEAN,sf:true},
            {p:"view",  r:UtilsReactDataGridIO.AkciorendereloNev.view}
        ]

        this.kezdoAllapot = {
            verzio:UtilsVSM.isEnvDev()?UtilsVSM.randomInt({min:1,max:20}):1, // Ha valtozik a kezdoallapot(konfiguracio) akkor noveld ezt meg, hogy ne a regi "rosszat toltse be"
            columns: tabledef.map(k=>{
                return UtilsReactDataGridIO.convertShortColumnDefinitionToOszlopKonfiguracio(k,this.renderelok)
            }),
            reservedViewportWidth: 0,
            enableFiltering: true,
            limit:10,
            backupverzio:1,
        }

        this.kezdoAllapot.columnOrder=this.kezdoAllapot.columns.map(e=>e.name)
        this.filterTypes = UtilsReactDataGridIO.createFilterTypes()
        this.filterValue= UtilsReactDataGridIO.createFilterValues(this.kezdoAllapot.columns)

        this.columnFilterSupported=true
        const browser = detect();

        if (browser && browser.os && 
            [
                "iPad Simulator",
                "iPhone Simulator",
                "iPod Simulator",
                "iPad",
                "Phone",
                "iPod",
                "IOS",
                "MAC"
            ].find(e => e.toLowerCase().includes(browser.os.toLowerCase()))            
        ) {
            const majorVersionFind = browser.version.match(/(\d+)\.\d+\.\d+/)
            const majorVersionOK=majorVersionFind[1] && majorVersionFind[1]>=16
            this.columnFilterSupported=
                browser.version.toLowerCase().includes("safari") && majorVersionOK
        }

        this.state = {
            allapot: this.kezdoAllapot,
            globalFilterText:"",
            selected: [],
            adatlap: {show:false,entitasid:null},
            confirmDeletionDialog: {show:false,entitasIDk:[]},
            dataSource: [],
            gridRef:null
        };
    }

    onAkcioAdatlapClicked=(id:number)=> {
        this.setState({adatlap:{show:true,entitasid:id}})
    }

    onSelectionChange = (e) => {
        if(e.selected===true) {
            let selected={}
            e.data.forEach((value, index) => {
                selected[value.id] = value;
            });
            this.setState({ 
                selected: selected
            })
        } else {
            this.setState({ 
                selected: e.selected
            })
        }
    }

    handleAdatlapShow = () => {
        this.setState({ adatlap: {show: true,entitasid:null} })
    }

    handleAdatlapClose = () => {
        this.setState({ adatlap: {show: false,entitasid:null} })
    }

    componentDidMount = () => {
        this.loadDatas()
        try {
            this.reloadTableStateFromLocalStorage()
        } catch (error) {
            this.setState({ allapot: this.kezdoAllapot })
        }
    }

    loadDatas = async () => {
        try {
            const works = await loadMyWorks()            
            this.setState({ dataSource: works })
        } catch(error) {
            console.log("LOAD ERROR !!!");
            console.log(error);
            console.log(error.data);
        }       
    }


    onColumnOrderChange = (columnOrder) => {
        this.setState({
            allapot: Object.assign({}, this.state.allapot, {
                columnOrder
            })
        }, this.saveTableStateToLocalStorage);
    }

    onBatchColumnResize = (batchColumnInfo, { reservedViewportWidth }) => {
        const colsMap = batchColumnInfo.reduce((acc, colInfo) => {
            const { column, width, flex } = colInfo
            acc[column.name] = { width, flex }
            return acc
        }, {})

        const columns = this.state.allapot.columns.map(c => {
            return Object.assign({}, c, colsMap[c.name])
        })

        this.setState({
            allapot: Object.assign({}, this.state.allapot, {
                columns,
                reservedViewportWidth
            })
        }, this.saveTableStateToLocalStorage)
    }

    onColumnVisibleChange = ({ column, visible }) => {
        const columns = this.state.allapot.columns.map((e) => {
            if (e.name == column.name) e.visible = visible
            return e
        })
        this.setState({
            allapot: Object.assign({}, this.state.allapot, {
                columns
            })
        }, this.saveTableStateToLocalStorage);
    }

    backupGridState = () => {
        this.saveTableStateToLocalStorage()
    }

    restoreGridState = () => {
        this.reloadTableStateFromLocalStorage()
    }

    saveTableStateToLocalStorage = () => {
        localStorage.setItem(
            this.tablaKontextusNev,
            JSON.stringify(this.state.allapot))
    }

    reloadTableStateFromLocalStorage = () => {
        const backupedStateStr = localStorage.getItem(this.tablaKontextusNev);
        if (!backupedStateStr) return;
        let backupedState = JSON.parse(backupedStateStr)
        if(backupedState.verzio!=this.kezdoAllapot.verzio) return;
        // A visszatoltott column allapothoz visszaalitjuk a render metodusokat
        backupedState.columns = backupedState.columns.map(
            (e:UtilsReactDataGridIO.OszlopKonfiguracio) => { 
                return UtilsReactDataGridIO.rebuildOszlopKonfiguracio(e,this.renderelok)
            })
        this.setState({ allapot: backupedState })
    }

    onEnableFilteringChange=(b)=> {
        this.setState({
            allapot: Object.assign({}, this.state.allapot, {enableFiltering:b})
        }, this.saveTableStateToLocalStorage);
    }

    onChangeFilterEnabled = (e) => {
        this.setState({
            allapot: Object.assign({}, this.state.allapot, {enableFiltering:e.target.checked})
        }, this.saveTableStateToLocalStorage);        
    }

    onGlobalFilterTextChanged = (e) => {
        this.setState({
            globalFilterText:e.target.value,
            selected: {}
        })
    }

    onLimitChanged=(n)=> {
        this.setState({
            allapot: Object.assign({}, this.state.allapot, {limit:n})
        }, this.saveTableStateToLocalStorage);
    }

    onFilterValueChanged=(e)=> {
        // Todo: tovabb kell fejleszteni: https://reactdatagrid.io/docs/api-reference#props-handle
        // Hogy csak azokat a kivalasztasokat toroljuk amik nem latszodnak
        this.setState({ selected: {} })    
    }

    onHandleGridRef=(r) => {
        this.setState({ gridRef: r })   
    }

    onSaveOrUpdateAdatlap=(newEntitas)=> {
        const modositas=[...this.state.dataSource];
        const newEntityNotOnTheList=!modositas.find(oldentitas=>oldentitas.id==newEntitas.id)
        if(newEntityNotOnTheList) {
            modositas[modositas.length]=newEntitas
        } else {
            modositas.forEach((oldentitas,index)=>{if(oldentitas.id==newEntitas.id) modositas[index]=newEntitas})
        }
        this.setState({dataSource:modositas})
    }
    
    render() {
        const rowHeight=40
        const calculatedHeight = 
            (this.state.dataSource.length>this.state.allapot.limit?
                this.state.allapot.limit:
                this.state.dataSource.length) * rowHeight
             + 100 + (this.state.allapot.enableFiltering?40:0)
        const gridStyle = { minHeight: calculatedHeight, zIndex:1 }
        const { selected } = this.state
        let datasource=this.state.dataSource
        const globalFilterText=this.state.globalFilterText

        if(globalFilterText)
            datasource=datasource.filter(obj => 
                Object.values(obj).some(val => 
                    val?.toString().toLowerCase().includes(globalFilterText.toLowerCase())));
   

        return (
            <>

                <div style={{display:this.state.adatlap.show?"none":"block"}}>

                    <h2 className="mb-4">My works</h2>

                    <div
                        className="p-3"
                        style={{
                            border: "1px solid lightgray",
                            borderRadius: "20px",
                            backgroundColor:"#fafafa",
                            
                        }}
                    >


                        {this.columnFilterSupported &&
                            <Form.Check
                                className="m-2 ms-0"
                                style={{ display: "inline-block" }}
                                type="switch"
                                id="custom-switch"
                                label="filtering"
                                checked={this.state.allapot.enableFiltering}
                                onChange={this.onChangeFilterEnabled}
                            />
                        }

                        {this.state.allapot.enableFiltering &&
                        <Form.Control
                            className="mb-2 ms-0 me-2"
                            type="text"
                            placeholder="Global filter"
                            style={{ display: "inline-block", width: "200px" }}
                            size="sm"
                            value={this.state.globalFilterText}
                            onChange={this.onGlobalFilterTextChanged}
                        />}

                        <ReactDataGrid
                            handle={this.onHandleGridRef}


                            selected={this.state.selected}
                            idProperty="id"

                            dataSource={datasource}
                            style={gridStyle}
                            enableSelection
                            multiSelect
                            defaultSortInfo={[]}
                            checkboxColumn
                            pagination="local"
                            onSelectionChange={this.onSelectionChange}

                            columns={this.state.allapot.columns}
                            reservedViewportWidth={this.state.allapot.reservedViewportWidth}

                            columnOrder={this.state.allapot.columnOrder}
                            onColumnOrderChange={this.onColumnOrderChange}
                            onBatchColumnResize={this.onBatchColumnResize}
                            onColumnVisibleChange={this.onColumnVisibleChange}

                            filterTypes={this.filterTypes}
                            defaultFilterValue={this.filterValue}

                            onEnableFilteringChange={this.onEnableFilteringChange}
                            enableFiltering={this.columnFilterSupported && this.state.allapot.enableFiltering}

                            onFilterValueChange={this.onFilterValueChanged}

                            limit={this.state.allapot.limit}
                            onLimitChange={this.onLimitChanged}
                        />

                    </div>
                </div>


                { 
                    (false || this.state.adatlap.show) &&

                    <div style={{maxWidth:992}}>
                        <WorkAdatlap 
                            id={this.state.adatlap.entitasid} 
                            key={this.state.adatlap.entitasid} 
                            onSaveOrUpdate={this.onSaveOrUpdateAdatlap}
                            button=
                                {<Button variant="primary" onClick={this.handleAdatlapClose} >
                                    Back
                                </Button>}
                        />
                    </div>
                }

            </>


        )


    }
}
