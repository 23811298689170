import TextField1 from '../comps/datasheet/mui/TextField1';
import TextFieldWithUnit1 from '../comps/datasheet/mui/TextFieldWithUnit1';
import TextArea1 from '../comps/datasheet/mui/TextArea1';
import dayjs, { Dayjs } from 'dayjs';
import DatePicker1 from '../comps/datasheet/mui/DatePicker1';
import DateTimePicker1 from '../comps/datasheet/mui/DateTimePicker1';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch'
import TimezoneSelect from 'react-timezone-select'
import React from 'react'
import ReactDOM from 'react-dom/client'

export function PropsLabelArrayMegjelenites ({
    obj,
    props_labels_renders,
    delimeter = " - ",
    enclosing = ["(",")"],
    showLabels = true
}) {
    return (
        (s=> s && (enclosing?.[0]||"")+s+(enclosing?.[1]||""))
        (props_labels_renders
            .filter(e=>obj?.[e.p || e])
            .map(e=>{
                let v=obj[e.p || e]
                if(e.r)
                    v=e.r(v)
                const l=showLabels?(e.l || e)+": ":""
                return l+v
            }).join(delimeter))   
    )
}


const createTextField = ({p,l,d,onChangeHandler,...rest}) => {
    return (
        <TextField1
            label={l}
            disabled={d?d:false}
            value={this.state.entitas?.[p] || ""}
            onChange={onChangeHandler?onChangeHandler:e => {
                console.log("Megvaltozott")
                this.setState(
                    prevstate => ({
                        entitas: { ...prevstate.entitas, [p]: e.target.value },
                        saved: false
                    })
                );
            }}
            {...rest}
        />)
}

const createTextFieldWithUnit = ({p,l,d,unit,onChangeHandler,...rest}) => {
    return (
        <TextFieldWithUnit1
            label={l}
            unit={unit}
            disabled={d?d:false}
            value={this.state.entitas?.[p] || ""}
            onChange={onChangeHandler?onChangeHandler:e => {
                console.log("Megvaltozott")
                this.setState(
                    prevstate => ({
                        entitas: { ...prevstate.entitas, [p]: e.target.value },
                        saved: false
                    })
                );
            }}
            {...rest}
        />)
}        

const createTextArea = ({p,l,d,onChangeHandler,...rest}) => {
    return (
        <TextArea1
            label={l}
            value={this.state.entitas?.[p] || ""}
            onChange={onChangeHandler?onChangeHandler:e => {
                console.log("Megvaltozott")
                this.setState(
                    prevstate => ({
                        entitas: { ...prevstate.entitas, [p]: e.target.value },
                        saved: false
                    })
                );
            }}
        />)
}     

const createDatePicker= ({label,prop,onChangeHandler}) => {
    return(
        <DatePicker1
            label={label}
            value={this.state.entitas?.[prop]?dayjs(this.state.entitas?.[prop]):null}
            onChange={onChangeHandler?onChangeHandler:v => {
                this.setState(
                    prevstate => ({
                        entitas: { ...prevstate.entitas, [prop]: v!=null?dayjs(v).format('YYYY-MM-DD'):null },
                        saved: false
                    })
                );
            }}
        />
    )
}

const createDateTimePicker= ({label,prop,onChangeHandler}) => {
    return(
        <DateTimePicker1
            label={label}
            value={this.state.entitas?.[prop]?dayjs(this.state.entitas?.[prop]):null}
            onChange={onChangeHandler?onChangeHandler:v => {
                this.setState(
                    prevstate => ({
                        entitas: { ...prevstate.entitas, [prop]: v!=null?dayjs(v).format('YYYY-MM-DD HH:mm:ss'):null },
                        saved: false
                    })
                );
            }}
        />
    )
}        

export const createSwitch=({
    prop,
    label,
    onChangeHandler=null,
    color=null,
    disabled=false,
    entitas,
    setState
}) => {
    return(
        <FormControlLabel control={
            <Switch
                {...(color!=null && { color: color })}
                {...(disabled==true && { disabled: true })}
                inputProps={{ 'aria-label': 'controlled' }}
                checked={entitas?.[prop]==null?false:entitas?.[prop]}
                onChange={onChangeHandler?onChangeHandler:event => {
                    setState(
                        prevstate => ({
                            entitas: { ...prevstate.entitas, [prop]: event.target.checked},
                            saved: false
                        })
                    );
                }}
            />
        } label={label} />                                
    )
}