import React from 'react';
import { IconProps } from '../icons/types';

export const EditIkon= ({ 
  height="15", 
  label="???",
  backgroundColor="#E85B2C", 
  textColor="",
  szelesseg=null,
  ...rest 
}) => {
  const texthossz=label.length
  const sz=szelesseg?szelesseg:texthossz*12.5+15
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={height}
      viewBox={"0 0 "+(sz)+" 23"}
      fill="currentColor"
      {...rest}
    >
      <rect x="0" y="0" width={sz} height="23" rx="10" ry="10" fill={backgroundColor}/>
      <text
        x="15"
        y="17"
        fill="#fff"
        fontFamily="'Quicksand-Bold', 'Quicksand'"
        fontSize="16"
        fontWeight="700"
      >
        {label.toUpperCase()}
      </text>
    </svg>
  );
};

export default EditIkon