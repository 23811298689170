import React,{ useContext } from 'react';
import { useState } from 'react'
import { ElsoContext } from '../UresjaratFigyelo/ElsoContext';
import { Sidebar, Menu, MenuItem, SubMenu, useProSidebar, menuClasses, MenuItemStyles, toggleSidebar } from 'react-pro-sidebar';

import { Link,useNavigate } from "react-router-dom";
import Nav from 'react-bootstrap/Nav';
import { useKeycloak } from '@react-keycloak/web';
import * as kcutils from "../Utils/keycloakutil"
import Logo from "./vau-staffing-logo-white.svg"
import MiddleLogo from "../assets/logo/MiddleLogoVauStaffing-White.svg"
import { BarChart } from '../assets/icons/BarChart';
import { Users } from '../assets/icons/Users';
import { Users2 } from '../assets/icons/Users2';
import { LogoutIcon } from "../assets/icons/LogoutIcon"
import { Minimize } from "../assets/icons/Minimize"
import { Maximize } from "../assets/icons/Maximize"
import { Client } from "../assets/icons/Client"
import { ClientEmployee } from "../assets/icons/ClientEmployee"

import { CheckIn } from "../assets/icons/CheckIn"
import { CheckOut } from "../assets/icons/CheckOut"


import { Key } from "../assets/icons/Key"
import { Config } from "../assets/icons/Config"
import { Document } from "../assets/icons/Document"
import { ClockSVG } from "../assets/icons/Clock"
import { Website } from "../assets/icons/Website"
import * as keycloakUtil from "../Utils/keycloakutil"
import * as UtilsVSM from "../UtilsVSM"
import {
    clearAccessToken as UtilsApiClearAccessToken
  } from "../Utils/UtilsApi"
import {checkOut as UtilsApiCheckOut} from "../Utils/UtilsApi"
import {loadMyActiveEmploymentRelationships as UtilsApi_LoadMyActiveEmploymentRelationships} from "../Utils/UtilsApi"
import {checkInAutomatic as UtilsApi_CheckInAutomatic} from "../Utils/UtilsApi"
import {getActiveWork as UtilsApi_GetActiveWork} from "../Utils/UtilsApi"
import {checkInIsAutomaticallyPossibleHere as UtilsApi_CheckInIsAutomaticallyPossibleHere} from "../Utils/UtilsApi"
import {getLocationOfBrowser as UtilsGeolocation_getLocationOfBrowser} from "../Utils/UtilsGeolocation"
import { Coords } from '../types/types';
import {components } from "../../../src-kozos/src/types/api-types"
import Swal from 'sweetalert2/dist/sweetalert2.js'
import {  showConfirm, showError, showInfo, showSuccess, showUtilsApiError, showWarning } from '../Utils/UtilsDialog';
import { Exception } from 'sass';

const theme = "dark";
const hasImage = true


// hex to rgba converter
const hexToRgba = (hex: any, alpha: any) => {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);

    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

const themes = {
    dark: {
        sidebar: {
            backgroundColor: '#082149e6',
            color: '#ffffff',
        },
        menu: {
            menuContent: '#082440',
            icon: '#cfdcf2',
            hover: {
                backgroundColor: '#23457B',
                color: '#b6c8d9',
            },
            disabled: {
                color: '#3e5e7e',
            },
        },
    },
};


export default function Sidebar1() {

    const { toggleSidebar, collapseSidebar, broken, collapsed } = useProSidebar();
    const { keycloak } = useKeycloak();
    const {loginTimeout}= useContext(ElsoContext)
    const navigate = useNavigate();    

    const menuItemStyles: MenuItemStyles = {
        root: {
            fontSize: "0.9rem",
            fontWeight: 600,
        },
        icon: {
            color: themes[theme].menu.icon,
            [`&.${menuClasses.disabled}`]: {
                color: themes[theme].menu.disabled.color,
            },
        },
        SubMenuExpandIcon: {
            color: '#ffffff',
        },
        subMenuContent: ({ level }) => ({
            backgroundColor:
                level === 0
                    ? hexToRgba(themes[theme].menu.menuContent, hasImage && !collapsed ? 0.4 : 1)
                    : 'transparent',
        }),
        button: {
            [`&.${menuClasses.disabled}`]: {
                color: themes[theme].menu.disabled.color,
            },
            '&:hover': {
                backgroundColor: hexToRgba(themes[theme].menu.hover.backgroundColor, hasImage ? 0.8 : 1),
                color: themes[theme].menu.hover.color,
            },
        },
        label: ({ open }) => ({
            fontWeight: open ? 600 : undefined,
        }),
    };

    const logut = () => {
        UtilsApiClearAccessToken()
        keycloak.logout();
    }

    const miniSidebar = () => {

    }
    
    const handleCheckIn = async() => {

        // try to get current poisition of employee
        let coords:Coords
        try {
            if(1+1==3)throw new Error("Hiba itt")
            coords = await UtilsGeolocation_getLocationOfBrowser();
        } catch (error) {
            await showError(error);
            return
        }
        // is there active work
        try {
            const activeWork= await UtilsApi_GetActiveWork()
            if(activeWork) {
                showWarning("There is already an active work. Please check-out first.")
                return;
            }
        } catch(error) {
            await showUtilsApiError(error)
            return;
        }         
        // If not, than we tray to check-in automaticaly
        let employmentRelationshipsCheckInPossible:components["schemas"]["WorkDTOForEmployee"][] | null=null
        try {
            employmentRelationshipsCheckInPossible= await UtilsApi_CheckInIsAutomaticallyPossibleHere(coords)
            console.log("Check-in possible here");
            console.log(employmentRelationshipsCheckInPossible)
        } catch(error) {
            await showUtilsApiError(error)
            return;
        } 
        
        if(employmentRelationshipsCheckInPossible?.length>1) {
            await showWarning("Overlaping zones. Please check-in manualy.")
            navigate(`/router/employment-relationships/actives`)
            return            
        } else if(employmentRelationshipsCheckInPossible?.length==1) {
            // Automatic check in possible
            let activeWork:components["schemas"]["WorkDTOForEmployee"] | null=null
            try {
                activeWork= await UtilsApi_CheckInAutomatic(coords)
            } catch(error) {
                await showUtilsApiError(error);
                return;
            }
            navigate(`/router/works/${activeWork.id}/${activeWork.version}`)
        } else {
            //  Automatic check in not-possible
            if(!employmentRelationshipsCheckInPossible) {
                await showWarning("You are not in any check-in zone!")
            } 
            let activeEmploymentRelationships:components["schemas"]["EmploymentRelationshipDTOForEmployee"][] | null=null
            try {
                activeEmploymentRelationships= await UtilsApi_LoadMyActiveEmploymentRelationships()              
            } catch(error) {
                await showUtilsApiError(error)
                return;
            }        
            if(!activeEmploymentRelationships || activeEmploymentRelationships?.length==0) {
                await showWarning("No active employment-relationship exists.")
                return
            }
            if(activeEmploymentRelationships?.length==1) {
                await showWarning("Please check-in again if you are in the zone.")
                navigate(`/router/employment-relationships/${activeEmploymentRelationships[0].id}/${activeEmploymentRelationships[0].version}`)
                return
            }            
            if(activeEmploymentRelationships?.length>1) {
                await showWarning("Please select an active employment-relationship.")
                navigate(`/router/employment-relationships/actives`)
                return
            }  
            navigate(`/router/employement-relationships`)
        } 
    }

    const handleCheckOut = async() => {
        const rst=await showConfirm("Confirm check-out?")                  
        if(!rst.isConfirmed) return;
        let work: components["schemas"]["WorkDTOForEmployee"] | null=null;
        try {
            work = await UtilsApiCheckOut()
        } catch(error) {
            await showUtilsApiError(error)
            return;
        } 
        if(work) {
            await showSuccess("Successful check-out") 
            navigate(`/router/works/${work.id}/${work.version}`)
        } else {
            await showWarning("There isn't any active work.");
        }
    }

    return (
        <>

            <Sidebar className="sidebar"
                backgroundColor={hexToRgba(themes[theme].sidebar.backgroundColor, hasImage ? 0.9 : 1)}
                rootStyles={{
                    color: themes[theme].sidebar.color, minWidth: "250"
                }}
                breakPoint="md"
            >
                <div style={{ textAlign: "center", padding: "20px", backgroundColor: "#1b2d4b" }}>
                    <img src={Logo} style={{ maxWidth: "100px" }} />
                    
                </div>
                <Menu menuItemStyles={menuItemStyles}>
                    <MenuItem component={<Link to="/router/employment-relationships" />} icon={<ClientEmployee/>}>My empl. relationships</MenuItem>
                    <MenuItem component={<Link to="/router/works" />} icon={<ClockSVG/>}>My works</MenuItem>
                    <MenuItem onClick={e=>{e.preventDefault;handleCheckIn()}} icon={<CheckIn/>}>
                        Check-in
                    </MenuItem>                    
                    <MenuItem onClick={e=>{e.preventDefault;handleCheckOut()}} icon={<CheckOut/>}>
                        Check-out
                    </MenuItem>
                    {(process.env.NODE_ENV == "development" ||  keycloakUtil.getLoggedInUser(keycloak)=="rbelatamas@gmail.com" ) &&
                        <SubMenu label="DEV" icon={<Config />}>
                            <MenuItem onClick={() => window.open(import.meta.env.VITE_KEYCLOAK_ADMIN_URL, '_blank')}>
                                Keycloak manager
                            </MenuItem>
                            <MenuItem onClick={() => window.open(import.meta.env.VITE_DB_URL, '_blank')}>
                                DB
                            </MenuItem>

                            <MenuItem onClick={() => window.open("https://reactdatagrid.io/docs/api-reference", '_blank')}>
                                Data Grid
                            </MenuItem>

                            <SubMenu label="Pro Sidebar">

                                <MenuItem onClick={() => window.open(
                                    "https://azouaoui-med.github.io/react-pro-sidebar/iframe.html?id=playground--playground&args=&viewMode=story",
                                    "_blank")}>
                                    Demo
                                </MenuItem>

                                <MenuItem onClick={() => window.open(
                                    "https://github.com/azouaoui-med/react-pro-sidebar#readme", '_blank')}>
                                    Github
                                </MenuItem>

                                <MenuItem onClick={() => window.open(
                                    "https://azouaoui-med.github.io/react-pro-sidebar/?path=/story/sidebar--basic", '_blank')}>
                                    Params
                                </MenuItem>                           


                            </SubMenu>

                            <MenuItem component={<Link to="/router/test1" />}>
                                Test 1
                            </MenuItem>


                        </SubMenu>
                    }

                    <MenuItem icon={<Website/>} onClick={() => window.open(
                        "https://vaustaffing.com/https://vaustaffing.com/", '_blank')}>
                        Vau Staffing site
                    </MenuItem>
                    <MenuItem onClick={logut} icon={<LogoutIcon />}>
                        Log-out 
                        {
                            loginTimeout!=null && 
                            loginTimeout>0 &&  
                            loginTimeout<60*5 && 
                            <> ({UtilsVSM.convertSecondsToMinutesAndSeconds(loginTimeout)}) </>
                        }
                    </MenuItem>
                    <MenuItem onClick={() => collapseSidebar()} icon={!collapsed ? <Minimize /> : <Maximize />}>Minimize</MenuItem>
                </Menu>

            </Sidebar>
        </>
    )
}



