import React from 'react'
import ReactDOM from 'react-dom/client'
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Popover from '../comps/Popover';
import FormGroup from '@mui/material/FormGroup';
import {loadMyWork} from "../Utils/UtilsApi"
import PropLabelMegjelenito from '../comps/PropLabelMegjelenito';
import {PropsLabelArrayMegjelenites,createSwitch} from "../Utils/UtilsVsmEmployee"

export default class WorkAdatlap extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.id = props.id
        this.additionalButton=props.button
        this.onSaveOrUpdateListener = props.onSaveOrUpdate
        this.entitas = this.props.entitas
        this.state = {
            entitas: {
                id: null
            },
            saved: this.entitas?.id ? true : false
        };        
    }


    /*
    ██       ██████   █████  ██████  ███████ ███    ██ ████████ ██ ████████  █████  ███████
    ██      ██    ██ ██   ██ ██   ██ ██      ████   ██    ██    ██    ██    ██   ██ ██
    ██      ██    ██ ███████ ██   ██ █████   ██ ██  ██    ██    ██    ██    ███████ ███████
    ██      ██    ██ ██   ██ ██   ██ ██      ██  ██ ██    ██    ██    ██    ██   ██      ██
    ███████  ██████  ██   ██ ██████  ███████ ██   ████    ██    ██    ██    ██   ██ ███████
    */

    loadEntitas = async (id) => {
        try {
            const work = await loadMyWork(id)
            this.setState({
                entitas: work,
                saved: work.id?true:false
            })
        } catch(error) {
            console.log("LOAD ERROR !!!");
            console.log(error);
            console.log(error.data);
        }        
    }

    /*
     ██████  ██████  ███    ███ ██████   ██████  ███    ██ ███████ ███    ██ ████████ ██████  ██ ██████  ███    ███  ██████  ██    ██ ███    ██ ████████
    ██      ██    ██ ████  ████ ██   ██ ██    ██ ████   ██ ██      ████   ██    ██    ██   ██ ██ ██   ██ ████  ████ ██    ██ ██    ██ ████   ██    ██
    ██      ██    ██ ██ ████ ██ ██████  ██    ██ ██ ██  ██ █████   ██ ██  ██    ██    ██   ██ ██ ██   ██ ██ ████ ██ ██    ██ ██    ██ ██ ██  ██    ██
    ██      ██    ██ ██  ██  ██ ██      ██    ██ ██  ██ ██ ██      ██  ██ ██    ██    ██   ██ ██ ██   ██ ██  ██  ██ ██    ██ ██    ██ ██  ██ ██    ██
     ██████  ██████  ██      ██ ██       ██████  ██   ████ ███████ ██   ████    ██    ██████  ██ ██████  ██      ██  ██████   ██████  ██   ████    ██
    */

    componentDidMount = () => {
        if (!this.entitas && this.id) this.loadEntitas(this.id)
    }


    /*
    ██████  ███████ ███    ██ ██████  ███████ ██████
    ██   ██ ██      ████   ██ ██   ██ ██      ██   ██
    ██████  █████   ██ ██  ██ ██   ██ █████   ██████
    ██   ██ ██      ██  ██ ██ ██   ██ ██      ██   ██
    ██   ██ ███████ ██   ████ ██████  ███████ ██   ██
    */

    render() {


        /*
        ██████  ███████ ████████ ██    ██ ██████  ███    ██
        ██   ██ ██         ██    ██    ██ ██   ██ ████   ██
        ██████  █████      ██    ██    ██ ██████  ██ ██  ██
        ██   ██ ██         ██    ██    ██ ██   ██ ██  ██ ██
        ██   ██ ███████    ██     ██████  ██   ██ ██   ████
        */

        const entitas=this.state.entitas
        const employee=entitas?.employee
        

        return (
            <>
                <h2 className="mb-4 pb-2">
                
                { this.state.entitas.id &&
                <Popover popovertext={"ID: "+this.state.entitas.id}>
                    My work 
                </Popover>
                }
                
                
                </h2>
                <div style={{ border: "solid 1px lightgray", padding: "20px", borderRadius: "20px", backgroundColor:"#fafafa", }}>
                    

                        <Row>
                            <Col className="col-12 p-2 pb-3 pb-sm-2">
                                {createSwitch({
                                    prop:"closedByAccounting",
                                    label:"Closed by accounting",
                                    color:"secondary",
                                    disabled:true,
                                    entitas:this.state.entitas,
                                    setState:this.setState
                                    })}
                            </Col>
                        </Row>

                    {/*
                    ███████ ███    ███ ██████  ██       ██████  ██    ██ ███████ ███████
                    ██      ████  ████ ██   ██ ██      ██    ██  ██  ██  ██      ██
                    █████   ██ ████ ██ ██████  ██      ██    ██   ████   █████   █████
                    ██      ██  ██  ██ ██      ██      ██    ██    ██    ██      ██
                    ███████ ██      ██ ██      ███████  ██████     ██    ███████ ███████
                    */}

                    <Row>
                        <Col className="col-12 p-2 pb-3 pb-sm-2">
                            <Popover popovertext={"ID: " + this.state.entitas?.employee?.id}>
                                Employee: &nbsp;
                                {

                                    PropsLabelArrayMegjelenites({
                                        obj: employee,
                                        enclosing:null,
                                        delimeter: " ",
                                        props_labels_renders: [
                                            "firstName",
                                            "lastName"],
                                        showLabels: false
                                    }) 

                                    
                                }
                                {
                                    employee?.socialSecurityNumber &&
                                    ` (SSN: ${employee.socialSecurityNumber})`
                                }
                            </Popover>

                           
                        </Col>
                    </Row>



                    {/*
                    ███████ ███    ███ ██████  ██       ██████  ██    ██ ███    ███ ███████ ███    ██ ████████
                    ██      ████  ████ ██   ██ ██      ██    ██  ██  ██  ████  ████ ██      ████   ██    ██
                    █████   ██ ████ ██ ██████  ██      ██    ██   ████   ██ ████ ██ █████   ██ ██  ██    ██
                    ██      ██  ██  ██ ██      ██      ██    ██    ██    ██  ██  ██ ██      ██  ██ ██    ██
                    ███████ ██      ██ ██      ███████  ██████     ██    ██      ██ ███████ ██   ████    ██


                    ██████  ███████ ██       █████  ████████ ██  ██████  ███    ██ ███████ ██   ██ ██ ██████
                    ██   ██ ██      ██      ██   ██    ██    ██ ██    ██ ████   ██ ██      ██   ██ ██ ██   ██
                    ██████  █████   ██      ███████    ██    ██ ██    ██ ██ ██  ██ ███████ ███████ ██ ██████
                    ██   ██ ██      ██      ██   ██    ██    ██ ██    ██ ██  ██ ██      ██ ██   ██ ██ ██
                    ██   ██ ███████ ███████ ██   ██    ██    ██  ██████  ██   ████ ███████ ██   ██ ██ ██


                    ██████  ██       ██████  ██   ██ ██   ██
                    ██   ██ ██      ██    ██ ██  ██  ██  ██
                    ██████  ██      ██    ██ █████   █████
                    ██   ██ ██      ██    ██ ██  ██  ██  ██
                    ██████  ███████  ██████  ██   ██ ██   ██
                    */}
                    <div className="mt-2">
                        <EmploymentRelationshipBlokk
                            employmentRelationship={entitas?.employmentRelationship}
                        />
                    </div>

                    {/*
                    ██     ██  ██████  ██████  ██   ██     ██████  ██       ██████  ██   ██ ██   ██
                    ██     ██ ██    ██ ██   ██ ██  ██      ██   ██ ██      ██    ██ ██  ██  ██  ██
                    ██  █  ██ ██    ██ ██████  █████       ██████  ██      ██    ██ █████   █████
                    ██ ███ ██ ██    ██ ██   ██ ██  ██      ██   ██ ██      ██    ██ ██  ██  ██  ██
                     ███ ███   ██████  ██   ██ ██   ██     ██████  ███████  ██████  ██   ██ ██   ██
                    */}

                    <div className="mt-4">
                        <CurrentWorkBlock work={entitas}/>
                    </div>

                    {/*
                    ███████ ██     ██ ██ ████████  ██████ ██   ██ ███████ ██   ██
                    ██      ██     ██ ██    ██    ██      ██   ██ ██      ██  ██
                    ███████ ██  █  ██ ██    ██    ██      ███████ █████   █████
                         ██ ██ ███ ██ ██    ██    ██      ██   ██ ██      ██  ██
                    ███████  ███ ███  ██    ██     ██████ ██   ██ ███████ ██   ██
                    */}

                    <Row>
                        <Col className="col-12 col-sm-9 p-2 pb-3 pb-sm-2">
                            <FormGroup>
                                {createSwitch({
                                    prop:"approvedByClient",
                                    label:"Approved by client",
                                    disabled:true,
                                    entitas:entitas,
                                    onChangeHandler:e => {
                                        const v= e.target.checked
                                        this.setState(
                                            prevstate => ({
                                                entitas: { 
                                                    ...prevstate.entitas, 
                                                    approvedByClient: e.target.checked,
                                                    ...(v && {problemReportedByClient: false}),
                                                    ...(v && {problemReportedByEmployee: false}),
                                                },
                                                saved: false
                                            })
                                        );
                                    }
                                })}
                                {createSwitch({
                                    prop:"problemReportedByClient",
                                    label:"Problem reported by client",
                                    color:"warning",
                                    disabled:true,
                                    entitas:entitas,
                                    onChangeHandler:e => {
                                        const v= e.target.checked
                                        this.setState(
                                            prevstate => ({
                                                entitas: { 
                                                    ...prevstate.entitas, 
                                                    problemReportedByClient: v,
                                                    ...(v && {approvedByClient: false})
                                                },
                                                saved: false
                                            })
                                        );
                                    }                                            
                                })}
                                {createSwitch({
                                    prop:"problemReportedByEmployee",
                                    label:"Problem reported by employee",
                                    color:"warning",
                                    entitas:entitas,
                                    onChangeHandler:e => {
                                        const v= e.target.checked
                                        this.setState(
                                            prevstate => ({
                                                entitas: { 
                                                    ...prevstate.entitas, 
                                                    problemReportedByEmployee: v,
                                                    ...(v && {approvedByClient: false})
                                                },
                                                saved: false
                                            })
                                        );
                                    }                                            
                                })}  
                                </FormGroup>                                
                        </Col>
                    </Row> 


                    {/*
                     █████  ██████  ██████  ██ ████████ ██  ██████  ███    ██  █████  ██
                    ██   ██ ██   ██ ██   ██ ██    ██    ██ ██    ██ ████   ██ ██   ██ ██
                    ███████ ██   ██ ██   ██ ██    ██    ██ ██    ██ ██ ██  ██ ███████ ██
                    ██   ██ ██   ██ ██   ██ ██    ██    ██ ██    ██ ██  ██ ██ ██   ██ ██
                    ██   ██ ██████  ██████  ██    ██    ██  ██████  ██   ████ ██   ██ ███████


                    ██████  ██    ██ ████████ ████████  ██████  ███    ██
                    ██   ██ ██    ██    ██       ██    ██    ██ ████   ██
                    ██████  ██    ██    ██       ██    ██    ██ ██ ██  ██
                    ██   ██ ██    ██    ██       ██    ██    ██ ██  ██ ██
                    ██████   ██████     ██       ██     ██████  ██   ████
                    */}                

                    <Row className="justify-content-between">
                        {
                            this.additionalButton &&
                            <Col>
                                    {React.cloneElement(this.additionalButton,{ className: "m-2 ms-0 mt-3" })}
                            </Col>
                        }
                    </Row>
                </div>
            </>
        );
    }
}

const CurrentWorkBlock = ({ work }) => {
    return (<>

        <Row>
            <Col className="col-12 p-2 pb-3 pb-sm-2">
                <Popover popovertext={"ID: " + work.id}>
                    Current work
                </Popover>
            </Col>
        </Row>

        <div style={{ border: "1px solid lightgray", padding: "15px", borderRadius: "15px" }}>
            <Row>
                <Col className="col-12 p-2 pb-3 pb-sm-2">
                    Time zone:&nbsp;
                    {work.timeZone}
                </Col>
            </Row>
            <Row>
                <Col className="col-12 p-2 pb-3 pb-sm-2">
                    {
                        [
                            {p:"checkIn",l:"check-in"},
                            {p:"checkOut",l:"check-out"},

                            {p:"hourlyRateForEmployee",l:"Hourly rate",r:v=>"US$ "+v},
                            {p:"hourlyRateForEmployeeOvertime",l:"Overtime",r:v=>"US$ "+v},
                        ].map( (e,i) => 
                            <PropLabelMegjelenito 
                                o={work}
                                key={i}
                                p={e.p}
                                l={e.l}
                                r={e.r}
                                />
                        )
                    }
                </Col>
            </Row>




            {
                work.deductibleLunchBreakDuration &&
                <Row>
                    <Col className="col-12 p-2 pb-3 pb-sm-2">
                        Deductible lunch break duration: {work.deductibleLunchBreakDuration} mins
                    </Col>
                </Row>
            }
        </div>
    </>)
}

const EmploymentRelationshipBlokk = ({ employmentRelationship }) => {
    return (<>


        <Row>
            <Col className="col-12 p-2 pb-3 pb-sm-2">
                <Popover popovertext={"ID: " + employmentRelationship?.id}>
                    Employment relationship
                </Popover>
            </Col>
        </Row>

        <div style={{ border: "1px solid lightgray", padding: "15px", borderRadius: "15px" }}>



            {employmentRelationship &&
                <Row>
                    <Col className="col-12 p-2 pb-3 pb-sm-2">
                        Client:&nbsp;
                        <Popover popovertext={"ID: " + employmentRelationship?.client?.id}>
                            {employmentRelationship?.client?.companyName}&nbsp;
                            {PropsLabelArrayMegjelenites({
                                obj: employmentRelationship?.client,
                                props_labels_renders: [
                                    "address",
                                    "city",
                                    "state",
                                    "zipCode"],
                                showLabels: false
                            })}
                        </Popover>
                    </Col>
                </Row>
            }




            {employmentRelationship &&
                <Row>
                    <Col className="col-12 p-2 pb-3 pb-sm-2">

                        <Popover popovertext={"ID: " + employmentRelationship?.id}>
                            Details:&nbsp;<br />
                            {PropsLabelArrayMegjelenites({
                                obj: employmentRelationship,
                                props_labels_renders: [
                                    { p: "hourlyRate", l: "Hourly rate", r: v => "US$ " + v },
                                    { p: "overTime", l: "Over time", r: v => "US$ " + v },
                                    "beginning",
                                    "ending"],
                                enclosing: null,
                                delimeter: ", ",
                                showLabels: true
                            })}
                        </Popover>
                    </Col>
                </Row>
            }


            {employmentRelationship &&
                <Row>
                    <Col className="col-12 p-2 pb-3 pb-sm-2">
                        Position:&nbsp;
                        <Popover popovertext={"ID: " + employmentRelationship?.client?.id}>
                            {employmentRelationship?.clientPosition?.name}
                        </Popover>
                    </Col>
                </Row>
            }


        </div>
    </>)
}

