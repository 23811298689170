import { Coords } from "../types/types";

/**
 * Meghatarozza a bongeszo koordinatait
 * Timeout, Accuracy, maximumAge
 * https://stackoverflow.com/a/53049933
 * https://developer.mozilla.org/en-US/docs/Web/API/Geolocation/getCurrentPosition
 * @returns Példa:{Latitude: 47.5636,Longitude: 19.0947}
 */
export async function getLocationOfBrowser():Promise<Coords> {
    return new Promise((resolve, reject) => {
        if ("geolocation" in navigator) {
            navigator.geolocation.getCurrentPosition(
                position => {
                    console.log("GeoLocation coords:");
                    console.log(position.coords);
                    console.log(`https://www.google.com/maps?q=${position?.coords?.latitude},${position?.coords?.longitude}`);
                    resolve(position.coords); // Sikeres esetben visszaadja a koordinátákat
                },
                error => {
                    switch (error.code) {
                        case error.PERMISSION_DENIED:
                            reject(new Error("The user has not allowed location."))
                            break;
                        case error.POSITION_UNAVAILABLE:
                            reject(new Error("The device cannot determine the position."))
                            break;
                        case error.TIMEOUT:
                            reject(new Error("The geolocation has timed out."))
                            break;
                        default:
                            reject(new Error("An unknown error has occurred."))
                    }
                },
                {
                    enableHighAccuracy: true,
                    timeout: 5000, // azaz 5 másodperc
                    maximumAge: 1000*60*5 // Azaz 5 perc
                }
            );
        } else {
            reject(new Error("Geolocation is not available."));
        }
    });
}