import { loadMyActiveEmploymentRelationships } from "../Utils/UtilsApi";
import MyEmploymentRelationshipTabla from "./MyEmploymentRelationshipTabla";

export function MyActiveEmployementRelationships({...args}) {


    return(
        <MyEmploymentRelationshipTabla  
            customListTitle="My active employment-relationships" 
            customLoadEntyties={loadMyActiveEmploymentRelationships}
            {...args}
        />
    )
}