import React from 'react';

export default function LayoutListView1({ title, children }) {
  return (
    <div className="container-fluid p-3">
      {title && <h2 className="mb-4">{title}</h2>}
      <div style={{ border: "1px solid lightgray", borderRadius: "20px",backgroundColor:"#fafafa" }}  className="p-3">
        {children}
      </div>
    </div>
  )
}
