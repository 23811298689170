import ReactDataGrid from '@inovua/reactdatagrid-community'
import '@inovua/reactdatagrid-community/index.css'
import { getNestedValue } from "./UtilsVSM"
import Igaz from "./assets/gui-check-yes-svgrepo-com.svg"
import Hamis from "./assets/dash-svgrepo-com.svg"
import BoolFilter from '@inovua/reactdatagrid-community/BoolFilter'
import { EditIkon } from "./assets/lists/EditIkon"

export const createGeneralRenders = ({ handleDatasheetClickEvent, handleDeleteClickEvent }) => {
    return {
        viewRender: RenderFactory.createClickableRender({
            label: <EditIkon label="view" szelesseg="70" height="16" backgroundColor="#f7941d" />,
            handleClickEvent: handleDatasheetClickEvent
        }),        
        datasheetRender: RenderFactory.createClickableRender({
            label: <EditIkon label="edit" height="16" backgroundColor="#f7941d" />,
            handleClickEvent: handleDatasheetClickEvent
        }),
        removeRender: RenderFactory.createClickableRender({
            label: <EditIkon label="delete" height="16" backgroundColor="#E6582B" />,
            handleClickEvent: handleDeleteClickEvent
        }),
        multiPropStringRender: RenderFactory.createMultiPropStringRender(),
        multiPropBooleanRender: RenderFactory.createMultiPropBooleanRender()
    }
}

export class RenderFactory {

    static createClickableRender({ label, handleClickEvent }) {
        return (d) => {
            return (
                <a
                    href="#"
                    data-id={d.data.id}
                    className="text-center d-block"
                    onClick={(e) => {
                        const dataID = e.currentTarget.getAttribute("data-id")
                        e.preventDefault()
                        handleClickEvent(dataID)
                    }}
                >{label}
                </a>
            )
        }
    }

    static createMultiPropStringRender = () => {
        return ({ cellProps, data }) => {
            const v = getNestedValue(data, cellProps.nestedprop)
            return v ? v.toString() : v;
        }
    }

    static createMultiPropBooleanRender = () => {
        return ({ cellProps, data }) => {
            return (
                <div className="text-center">
                    <img
                        src={getNestedValue(data, cellProps.nestedprop) ? Igaz : Hamis}
                        style={{ width: "15px" }} />
                </div>
            )
        }
    }

}

export function createColumnDefinifitions(tabledef, renders) {
    return tabledef.map(({ prop, label, renderelo, szuro }) => {
        label = label ? label : prop
        let elso = {
            name: prop,
            header: label,
            nestedprop: prop,
            visible: true,
            width: label.length * 10 + 30,
            headerAlign: 'center',
            showColumnMenuFilterOptions: szuro ? true : false,
            renderelo: renderelo,
            render: renders[renderelo],
            sort:nullSafeStringComparator
        }
        if (szuro == "bool")
            elso.filterEditor = BoolFilter
        return elso
    })
}

export function nullSafeStringComparator(
    a,
    b
) {
    if(
        (a!=null && a!=undefined && !isNaN(a)) &&
        (b!=null && b!=undefined && !isNaN(b))
    ) return Number(a)-Number(b);
    return String(a==null||a==undefined?"":a).localeCompare(String(b==null||b==undefined?"":b));
}

export function createFilterDefinifitions(tabledef) {
    return tabledef.filter(({ szuro }) => szuro).map(({ prop, label, renderelo, szuro }) => {
        const fsz = {
            name: prop,
            operator: (function (sz) {
                switch (sz) {
                    case "nested": return "Include"
                    case "bool": return "eq"
                }
            })(szuro),
            type: szuro,
            value: null
        }
        return fsz;
    })
}


export const filterTypes = Object.assign({}, ReactDataGrid.defaultProps.filterTypes, {
    nested: {
        name: 'nested',
        operators: [
            {
                name: 'Include',
                fn: ({ data, column, filterValue }) => {
                    if (!filterValue) return true;
                    const v = column.nestedprop ?
                        getNestedValue(data, column.nestedprop) :
                        data[column.name]
                    return v && v.toString().toLowerCase().includes(filterValue.toLowerCase());
                }
            },
            {
                name: 'Start with',
                fn: ({ data, column, filterValue }) => {
                    if (!filterValue) return true;
                    const v = column.nestedprop ?
                        getNestedValue(data, column.nestedprop) :
                        data[column.name]
                    return v && v.toString().toLowerCase().startsWith(filterValue.toLowerCase());
                }
            },
            {
                name: 'Empty',
                fn: ({ data, column, filterValue }) => {
                    const v = column.nestedprop ?
                        getNestedValue(data, column.nestedprop) :
                        data[column.name]
                    return v ? false : true;
                }
            },
            {
                name: 'Not empty',
                fn: ({ data, column, filterValue }) => {
                    const v = column.nestedprop ?
                        column.nestedprop.split(".").reduce((data, property) => data[property], data) :
                        data[column.name]
                    return v ? true : false;
                }
            },
        ]
    }
})