import { useState, useMemo, useCallback, useEffect, useRef, forwardRef, useImperativeHandle } from 'react'
import "leaflet/dist/leaflet.css"
import "leaflet-draw/dist/leaflet.draw.css"
import L from "leaflet";
import "leaflet-draw";
import "leaflet-control-geocoder/dist/Control.Geocoder.css"
import "leaflet-control-geocoder"
import {Coords, CoordsShort} from "../types/types"

// Event-ek: leaflet draw
// https://leaflet.github.io/Leaflet.draw/docs/leaflet-draw-latest.html#l-draw-event


// VALAMIERT MINDIG MINDEN PROPS VALTOZIK, DE NEM TUDOM, HOGY MIERT
// csak akkor nem valtoznak, ha primitív típusok
const Map = ({
    centerAndZoomValue,
    polygonValue, 
    markerCoords  
}:{
    centerAndZoomValue:any,
    polygonValue:any,     
    markerCoords:{lat:number,lng:number} | null
}) => {
    const lasVegasCoord=[36.125,-115.175]
    const defaultZoom=10
    const mapContainerRef = useRef(null);
    const mapRef = useRef(null);
    const drawnItemsRef = useRef(null);
    const polygonRef =useRef(null);
    const markerRef = useRef(null);
  
    useEffect(() => {
        const map = L.map(mapContainerRef.current).setView(
            centerAndZoomValue?.center!=null?centerAndZoomValue?.center:lasVegasCoord, 
            centerAndZoomValue?.zoom!=null?centerAndZoomValue?.zoom:defaultZoom, 
        );
        mapRef.current=map

        L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
            attribution: '© OpenStreetMap contributors'
        }).addTo(map);

        const drawnItems = new L.FeatureGroup();
        drawnItemsRef.current=drawnItems
        map.addLayer(drawnItems);

        return () => {
            map.remove();
        };
    }, [mapContainerRef.current,mapRef.current]); 

    // A hook-ok a definiálási sorrendjükben futnak le

    // CENTER és ZOOM beállítása
    useEffect(()=>{
        if(
            mapContainerRef.current!=null && 
            mapRef.current!=null && 
            mapRef.current.setView!=null  && 
            centerAndZoomValue!=null 
        ) {
            mapRef.current.setView(
                centerAndZoomValue?.center!=null?centerAndZoomValue?.center:lasVegasCoord, 
                centerAndZoomValue?.zoom!=null?centerAndZoomValue?.zoom:defaultZoom, 
            );
        }
    },[centerAndZoomValue])  

    // A hook-ok a definiálási sorrendjükben futnak le

    // Polygon kirajzolása
    useEffect(()=>{
        if(
            mapContainerRef.current!=null && 
            mapRef.current!=null && 
            mapRef.current.setView!=null  && 
            drawnItemsRef.current!=null &&
            polygonValue!=null 
        ) {
            if(polygonRef.current)
                drawnItemsRef.current.removeLayer(polygonRef.current)
            polygonRef.current = L.polygon(polygonValue)
            drawnItemsRef.current.addLayer(polygonRef.current)
            const vannakPolygonkoordinatak=polygonRef?.current?.getLatLngs()?.[0]?.length>1
        }
     },[polygonValue,polygonRef.current])   
    
    // A hook-ok a definiálási sorrendjükben futnak le
    useEffect(()=>{
        if(
            mapContainerRef.current!=null && 
            mapRef.current!=null && 
            mapRef.current.setView!=null  && 
            drawnItemsRef.current!=null &&
            markerCoords!=null 
        ) {
            if(markerRef.current)
                drawnItemsRef.current.removeLayer(markerRef.current)
            markerRef.current = L.marker(markerCoords)
            drawnItemsRef.current.addLayer(markerRef.current) 
        }
    },[markerCoords,markerRef.current])       

    useEffect(()=>{
        if(markerCoords) {
            mapFitToAll()
        } else 
            mapFitToPolygon()
    },[markerCoords,polygonValue])  

    /*
    ██████  ███████ ███████  ██████   ██████  ██       █████  ██       ██████
    ██   ██ ██      ██      ██    ██ ██       ██      ██   ██ ██      ██    ██
    ██████  █████   █████   ██    ██ ██   ███ ██      ███████ ██      ██    ██
    ██   ██ ██      ██      ██    ██ ██    ██ ██      ██   ██ ██      ██    ██
    ██████  ███████ ██       ██████   ██████  ███████ ██   ██ ███████  ██████
    */



    const mapFitToPolygon=()=> {
        if(!mapRef?.current) return
        if(!polygonRef?.current) return
        const bounds=polygonRef.current.getBounds();
        mapRef.current.fitBounds(bounds);
    }

    const mapFitToMarker=()=> {
        if(!mapRef?.current) return
        if(!markerRef?.current) return
        mapRef.current.setView(markerRef.current.getLatLng(),16);
    }    

    const mapFitToAll = () => {
        const map=mapRef?.current
        if(!map) return
        const marker=markerRef?.current
        const polygon=polygonRef?.current
        const coordinates=[]
        if(marker)
            coordinates.push(marker?.getLatLng())
        if(polygon)
            coordinates.push(...polygon?.getLatLngs()[0])

        if(coordinates.length==0) return


        // Kezdetben az első koordinátákat tekintjük a bounding box kezdeti értékeinek.
        let minLat = coordinates[0].lat;
        let maxLat = coordinates[0].lat;
        let minLng = coordinates[0].lng;
        let maxLng = coordinates[0].lng;

        // Végigiterálunk az összes koordinátán és frissítjük a bounding box-ot, ha szükséges.
        for (const coord of coordinates) {
            if (coord.lat < minLat) minLat = coord.lat;
            if (coord.lat > maxLat) maxLat = coord.lat;
            if (coord.lng < minLng) minLng = coord.lng;
            if (coord.lng > maxLng) maxLng = coord.lng;
        }

        // Az eredményül kapott bounding box értékeket most használhatod a térkép középpontjának és méretezésének beállításához.
        // Ezt a kódrészletet Leaflet vagy más térkép API-hez kell illeszteni.

        // Például a Leaflet térkép esetén:
        const mapCenter = [(minLat + maxLat) / 2, (minLng + maxLng) / 2];
        const mapBounds = [[minLat, minLng], [maxLat, maxLng]];

        // Térkép középpontjának beállítása
        map.setView(mapCenter);

        // Térkép méretezése úgy, hogy az összes koordináta látható legyen
        map.fitBounds(mapBounds);        
    }    

    
    /*
    ██████  ███████ ████████ ██    ██ ██████  ███    ██
    ██   ██ ██         ██    ██    ██ ██   ██ ████   ██
    ██████  █████      ██    ██    ██ ██████  ██ ██  ██
    ██   ██ ██         ██    ██    ██ ██   ██ ██  ██ ██
    ██   ██ ███████    ██     ██████  ██   ██ ██   ████


    */

    console.log("rerender");

    return (
        <>
           
            <div ref={mapContainerRef} style={{ zIndex:1,width: "100%", height: '400px', border:"solid 1px gray" }} />
            <a href="#" onClick={(e)=>{e.preventDefault();mapFitToPolygon()}}>Show workplace</a>&nbsp;|  &nbsp;
            {
                markerCoords &&
                <><a href="#" onClick={(e)=>{e.preventDefault();mapFitToMarker()}}>Show my position</a>&nbsp;|  &nbsp;</>
            }   
            {
                markerCoords &&
                <><a href="#" onClick={(e)=>{e.preventDefault();mapFitToAll()}}>Show both</a>&nbsp;|  &nbsp;</>
            }                      
            <a href="#" onClick={
                (e)=>{
                    e.preventDefault()
                    const polygonCenterCoords=polygonRef?.current?.getBounds()?.getCenter()
                    const markerCoords=markerRef?.current?.getLatLng()
                    console.log("polygonCenterCoords")
                    console.log(polygonCenterCoords)
                    console.log("markerCoords")
                    console.log(markerCoords)
                    let url
                    if(polygonCenterCoords && markerCoords)
                        url=
                            "https://www.google.com/maps/dir/"+
                            `${polygonCenterCoords?.lat},${polygonCenterCoords?.lng}/`+
                            `${markerCoords?.lat},${markerCoords?.lng}/`
                    else
                        url=`https://www.google.com/maps?q=${polygonCenterCoords?.lat},${polygonCenterCoords?.lng}`
                    window.open(url, '_blank');
            }} target="_blank">Google Maps...</a>
        </>


    );

};

export default Map

function convertToLatLngArray(coords) {
    return coords.map(coord => convertToLatLng(coord));
}

function convertToLatLng(coord) {
    return [coord.lat, coord.lng];
}

function inside(point, vs) {
    // ray-casting algorithm based on
    // https://wrf.ecse.rpi.edu/Research/Short_Notes/pnpoly.html

    var x = point[0],
        y = point[1];

    var inside = false;
    for (var i = 0, j = vs.length - 1; i < vs.length; j = i++) {
        var xi = vs[i][0],
            yi = vs[i][1];
        var xj = vs[j][0],
            yj = vs[j][1];

        var intersect = ((yi > y) != (yj > y)) &&
            (x < (xj - xi) * (y - yi) / (yj - yi) + xi);
        if (intersect) inside = !inside;
    }
    return inside;
};




