import React from 'react';
import { IconProps } from './types';

export const CheckIn: React.FC<IconProps> = ({ size = 18, ...rest }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 94 85"
      fill="currentColor"
      {...rest}
    >
<g id="SVGRepo_iconCarrier"><path d="M0,16.938l0,50.912c0,4.688 1.954,8.854 5.079,12.108c3.125,3.125 7.163,4.817 11.85,4.817l25.521,0l-0,-10.546l-25.521,0c-3.517,0 -6.25,-2.867 -6.25,-6.379l0,-50.912c0,-3.517 2.733,-6.25 6.25,-6.25l25.521,-0l-0,-10.68l-25.521,0c-4.687,0 -8.725,1.825 -11.85,4.95c-3.125,3.254 -5.079,7.292 -5.079,11.979Z" style={{fillRule:"nonzero"}}/><path d="M93.352,32.304l0,20.313c0,2.346 -2.083,4.296 -4.429,4.296l-23.567,-0l0,16.016c0,1.434 -0.783,2.604 -2.083,3.254c-0.521,0.13 -1.042,0.13 -1.304,0.13c-0.913,-0 -1.692,-0.263 -2.346,-0.913l-30.6,-30.6c-1.433,-1.171 -1.304,-3.517 -0,-4.817l30.6,-30.471c1.825,-1.954 5.729,-0.783 5.729,2.346l0,16.146l23.567,0c2.346,0 4.429,1.954 4.429,4.296l0.004,0.004Z" style={{fillRule:"nonzero"}}/></g>
    </svg>
  );
};




