import axios from 'axios';
import {CustomError} from './CustomError';
import {Coords} from "../types/types"
import {components } from "../../../src-kozos/src/types/api-types"

const axiosInstance1 = axios.create({
    baseURL: import.meta.env.VITE_SPRING_CONTEXT_PATH + "/api-for-employee/",
});

export function setAccessToken(token) {
    axiosInstance1.defaults.headers.common['Authorization'] = "Bearer " + token
    console.log(token);
}

export function clearAccessToken() {
    axiosInstance1.defaults.headers.common['Authorization'] = ""
}

axiosInstance1.interceptors.request.use(
    (config)=> {
        return config;
    },
    (error)=> {
        // Do something with request error
        return Promise.reject(error);
    }
);

export interface UtilsApiError {
    response:object,
}

axiosInstance1.interceptors.response.use(
    null, // (esponse) => {return repsonse} mellozese, ez a blokk csak akkor fut le, ha a status 200
    (error) => { //  Ez akkor fut le ha a status nem 200 (felulirható)
        throw error
    }
);


/**
 * @throws {ApiError} Amikor valami rosszul megy.
 */
export const loadMyWork = async (id:number):Promise<components["schemas"]["WorkDTOForEmployee"]> => {
    const resp = await axiosInstance1.get("/my-works/" + id);
    return resp.data;
};

export const loadMyWorks = async ():Promise<components["schemas"]["WorkDTOForEmployee"][]>=> {
    const resp = await axiosInstance1.get("/my-works");
    return resp.data;
};

export const loadMyEmploymentRelationships = async ():Promise<components["schemas"]["EmploymentRelationshipDTOForEmployee"][]> => {
    const resp = await axiosInstance1.get("/my-employment-relationships");
    return resp.data;
};

export const loadMyEmploymentRelationship = async (id:number):Promise<components["schemas"]["EmploymentRelationshipDTOForEmployee"]> => {
    const resp = await axiosInstance1.get("/my-employment-relationships/"+id);
    return resp.data;
};

export const loadMyActiveEmploymentRelationships = async () => {
    const resp = await axiosInstance1.get("/my-employment-relationships/actives");
    return resp.data;
};

export const checkInAutomatic = async ({latitude,longitude}:Coords):Promise<components["schemas"]["WorkDTOForEmployee"]> => {
    const resp = await axiosInstance1.post("/check/in/automatic",{lat:latitude,lng:longitude});
    return resp.data;
};

export const checkInToSpecificEmploymentRelationship = async (
    {latitude,longitude}:Coords,
    employmentRelationShipID:number
):Promise<components["schemas"]["WorkDTOForEmployee"]> => {
    const resp = await axiosInstance1.post(
        "/check/in/specific",
        {
            coords:{lat:latitude,lng:longitude},
            employmentRelationShipID
        });
    return resp.data;
};

export const checkInIsAutomaticallyPossibleHere = 
async ({latitude,longitude}:Coords):Promise<components["schemas"]["WorkDTOForEmployee"][]> => {
    const resp = await axiosInstance1.post("/check/in/automatic/possible",{lat:latitude,lng:longitude});
    return resp.data;
};

/** return true if we have active work */ 
export const hasActiveWork = async ():Promise<boolean>  => {
    const resp = await axiosInstance1.get("/my-works/has-active-work");
    return resp.data;
};

/** return the work from which we checked-out */
export const checkOut = async () :Promise<components["schemas"]["WorkDTOForEmployee"]> => {
    const resp = await axiosInstance1.patch("/check/out");
    return resp.data;
};

export const getActiveWork = async ():Promise<components["schemas"]["WorkDTOForEmployee"]> => {
    const resp = await axiosInstance1.get("/my-works/active");
    return resp.data;
};



